import { Image, createStyles } from '@mantine/core';

const useStyles = createStyles(theme => ({
  logo: {
    height: 'auto',
    width: 'auto',
    display: 'block',
  },
}));

const Logo = ({ size }) => {
  const { classes } = useStyles();

  return (
    <Image
      height={size ? size : '100'}
      width={size ? size : '100'}
      src='image/logo_cybernetic_synapse_full.png'
      alt='devpods.ai logo'
      className={classes.logo}
    />
  );
};

export default Logo;
