import { Carousel } from '@mantine/carousel';
import { Container, Title, createStyles, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import FeatureCard from './FeatureCard';

const useStyles = createStyles(theme => ({
  wrapper: {
    height: '100vh',
  },

  title: {
    textAlign: 'center',
    color: '#FFF',
    marginBottom: `calc(${theme.spacing.xl} * 2)`,
  },
}));

const features = [
  {
    stage: 'Branding',
    description: 'Logo, tagline, brand color palette, and fonts',
    image: 'image/poster_branding_stage.png',
  },
  {
    stage: 'Problem Discovery',
    description: 'User personas with motivations and challenges',
    image: 'image/poster_problem_discovery_stage.png',
  },
  {
    stage: 'Solution Discovery',
    description: 'Jobs to be done, user stories, and user flows',
    image: 'image/poster_solution_discovery_stage.png',
  },
  {
    stage: 'Product Design',
    description: 'Design system, information architecture, navigation, mocks, and prototypes',
    image: 'image/poster_design_stage.png',
  },
  {
    stage: 'Development',
    description: 'Frontend app with backend services using modern languages and frameworks',
    image: 'image/poster_development_stage.png',
  },
  {
    stage: 'Launch',
    description: 'Deployed to the web, Apple App Store, and Google Play',
    image: 'image/poster_launch_stage.png',
  },
];

const FeatureCarousel = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const slides = features.map(item => (
    <Carousel.Slide key={item.stage}>
      <FeatureCard {...item} />
    </Carousel.Slide>
  ));

  return (
    <Container className={classes.wrapper}>
      <Title className={classes.title}>Features</Title>
      <Carousel
        slideSize='50%'
        breakpoints={[{ maxWidth: 'sm', slideSize: '100%', slideGap: 0 }]}
        slideGap='xl'
        align='start'
        slidesToScroll={mobile ? 1 : 2}
        controlSize={40}
      >
        {slides}
      </Carousel>
    </Container>
  );
};

export default FeatureCarousel;
