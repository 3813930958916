import { createStyles, Container, Title, Button, Text, rem } from '@mantine/core';

const useStyles = createStyles(theme => ({
  hero: {
    position: 'relative',
    height: '80vh',
    backgroundImage: 'url(image/devpods-bg-dark.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingBottom: `calc(${theme.spacing.xl} * 6)`,
    position: 'relative',

    [theme.fn.smallerThan('sm')]: {
      height: rem(500),
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },
  },

  title: {
    color: theme.white,
    fontSize: rem(60),
    fontWeight: 900,
    lineHeight: 1.1,

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(40),
      lineHeight: 1.2,
    },

    [theme.fn.smallerThan('xs')]: {
      fontSize: rem(28),
      lineHeight: 1.3,
    },
  },

  description: {
    color: theme.white,
    maxWidth: 600,

    [theme.fn.smallerThan('sm')]: {
      maxWidth: '100%',
      fontSize: theme.fontSizes.sm,
    },
  },

  control: {
    marginTop: `calc(${theme.spacing.xl} * 1.5)`,

    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },
}));

const HeroContent = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.hero}>
      <Container className={classes.container}>
        <Title className={classes.title}>Bring your app idea to life with the power of AI-driven development</Title>
        <Text className={classes.description} size='xl' mt='xl'>
          From idea to app in hours, not months. It will feel like you have an entire product development team at your
          disposal, because you do (shhh, they're robots)! Sign up now for early access.
        </Text>

        <Button variant='filled' size='xl' radius='xl' className={classes.control}>
          Join the waitlist
        </Button>
      </Container>
    </div>
  );
};

export default HeroContent;
