import { Container, Text, Timeline, Title, createStyles } from '@mantine/core';
import { TbBeta, TbCheck, TbRobot, TbSignature } from 'react-icons/tb';
import { SiAppstore } from 'react-icons/si';

const useStyles = createStyles(theme => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  title: {
    textAlign: 'center',
    color: '#FFF',
    marginBottom: `calc(${theme.spacing.xl} * 2)`,
  },
}));

const HowItWorks = () => {
  const { classes } = useStyles();

  return (
    <Container className={classes.wrapper}>
      <Title className={classes.title}>How It Works</Title>
      <Timeline active={0} lineWidth={6} bulletSize={30}>
        <Timeline.Item bullet={<TbBeta />} title='Join the waitlist'>
          <Text color='dimmed' size='sm'>
            Sign up for early access to the beta
          </Text>
        </Timeline.Item>
        <Timeline.Item bullet={<TbSignature />} title='Sign up'>
          <Text color='dimmed' size='sm'>
            Create an account and provide details about your app idea
          </Text>
        </Timeline.Item>
        <Timeline.Item bullet={<TbRobot />} title='Collaborate with our AI agents'>
          <Text color='dimmed' size='sm'>
            Our AI-driven platform will guide you through the development stages, from branding through!
          </Text>
        </Timeline.Item>
        <Timeline.Item bullet={<TbCheck />} title='Delivery'>
          <Text color='dimmed' size='sm'>
            We will securely send you all assets and source for a fully functional MVP built using the latest
            technologies
          </Text>
        </Timeline.Item>
        <Timeline.Item bullet={<SiAppstore />} title='Launch'>
          <Text color='dimmed' size='sm'>
            We can also launch your app to the web, the Apple App Store, and Google Play automatically so you can start
            acquiring users today!
          </Text>
        </Timeline.Item>
      </Timeline>
    </Container>
  );
};

export default HowItWorks;
