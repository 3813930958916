import { ActionIcon, Anchor, Container, Group, Title, createStyles, rem } from '@mantine/core';
import { TbBrandTwitter } from 'react-icons/tb';
import Logo from './Logo';

const useStyles = createStyles(theme => ({
  footer: {
    marginTop: rem(120),
    borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
}));

const FooterLink = ({ label, href }) => {
  return (
    <Anchor color='dimmed' href={href} onClick={event => event.preventDefault()} size='sm'>
      {label}
    </Anchor>
  );
};

const Footer = ({ links }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Group>
          <Logo size={64} />
          <Title order={2}>devpods.ai</Title>
        </Group>
        <Group className={classes.links}>
          <FooterLink label='Contact' href='#' />
          <FooterLink label='Privacy' href='#' />
          <Anchor href='https://twitter.com/devpodsAI' target='_blank' rel='noopener noreferrer'>
            <ActionIcon size='lg'>
              <TbBrandTwitter />
            </ActionIcon>
          </Anchor>
        </Group>
      </Container>
    </div>
  );
};

export default Footer;
