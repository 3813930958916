import { Button, Paper, Text, Title, createStyles, rem } from '@mantine/core';

const useStyles = createStyles(theme => ({
  card: {
    width: '100%',
    aspectRatio: '3 / 4',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: theme.colors.dark[7],
  },

  description: {
    fontWeight: 900,
    color: theme.white,
    lineHeight: 1.2,
    fontSize: rem(32),
    marginTop: theme.spacing.xs,
  },

  stage: {
    color: theme.white,
    opacity: 0.7,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
}));

const FeatureCard = ({ image, stage, description }) => {
  const { classes } = useStyles();
  const gradient = { from: 'yellow.4', to: 'yellow.9', deg: 45 };

  return (
    <Paper
      p='xl'
      radius='md'
      sx={{
        backgroundImage: `linear-gradient(150deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 50%), url(${image})`,
      }}
      className={classes.card}
    >
      <div>
        <Text variant='white' className={classes.stage} size='xs'>
          {stage}
        </Text>
        <Title variant='white' order={3} className={classes.description}>
          {description}
        </Title>
      </div>
      <Button variant='gradient' gradient={gradient} color='dark'>
        Learn more
      </Button>
    </Paper>
  );
};

export default FeatureCard;
