import React from 'react';
import { createStyles } from '@mantine/core';
import TopNav from './TopNav';
import HeroContent from './HeroContent';
import FeatureCarousel from './FeatureCarousel';
import HowItWorks from './HowItWorks';
import Footer from './Footer';

const useStyles = createStyles(theme => ({
  app: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    height: '100vh',
    margin: 0,
    padding: 0,
  },
}));

const App = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.app}>
      <TopNav />

      <HeroContent />

      <FeatureCarousel />

      <HowItWorks />

      <Footer />
    </div>
  );
};

export default App;
